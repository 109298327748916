import React, {useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/contact_new.png";
import {serverUtilities} from '../../services/serverutilities.service';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0 lg:px-0 xs:px-10`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-left md:text-left`;

const Subheading = tw(SubheadingBase)`text-left md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-left md:text-left leading-tight`;
const Description = tw.p`mt-4 text-left md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-black`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-brand-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`
const ErrorLabel = tw.p`text-brand-700 text-xl mt-5`;


//const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`
const SubmitButton = tw.button`w-full sm:w-64 mt-6 py-3 bg-brand-300 text-white rounded font-bold tracking-wide text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-brand-100 hover:text-white hocus:-translate-y-px`;

export default ({
  subheading = "Contact Us",
  heading = <><span tw="text-brand-500">Get in Touch</span><wbr/> Now.</>,
  description = <>Drop in at Our Office at <b>Netaji Subhash Place, Delhi - 110034</b> or Fill out this form to start a conversation with a Young Minds Team Member about your Content Delivery and get your questions answered.</>,
  submitButtonText = "Submit",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
  fromPage = "",
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  let fields = {};
  const [msgVal, setError] = useState('');
  const [isDisable , setDisable] = useState('0');
  const handleChange = (e) => {
    //////console.log(e.target.name);
    fields[e.target.name] = e.target.value; 
  }
  const sendLogin = (e) => {
    //////console.log(fields);
    //alert ("1");
    //////console.log("I am clicked");
    //e.preventDefault();
    fields['fname'] = document.getElementById('fname-input').value;
    fields['phone'] = document.getElementById('phone-input').value;
    fields['email'] = document.getElementById('email-input').value;
    fields['message'] = document.getElementById('message-input').value;
    fields['refUrl'] = window.location.href;
    //e.preventDefault();
    //////console.log(validateForm());
    if(validateForm()) {
      //////console.log("valid true");
      serverUtilities.addlead(fromPage, fields.fname, fields.lname, fields.email, fields.phone, fields.message, fields.refUrl).then(leadData=>{
        //////console.log(leadData);
        if(leadData.status == '0'){
          setError(leadData.message);
          setDisable(leadData.status);
        }
        else
        {
          setError(leadData.message);
          setDisable(leadData.status);
        }
      });
    }
  }
  function validateForm(){
    let formIsValid;
    setError('');
    const Emailregex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,3})$/i;
    if(fields.fname == ''){
      setError("*Please Enter Your Name");
      document.getElementById('fname-input').focus();
      formIsValid = false;
      return formIsValid;
    }
    // if(fields.fname != null){
    //   if (fields.fname.match(/^[a-zA-Z]+$/)) {
    //     setError("*Please type only letters");
    //     document.getElementById('fname-input').focus();
    //   }
    // }
    if(fields.fname.length != ''){
      if(fields.fname.length < 3){
        setError("*Please Enter Your Name between 4 to 8 characters");
        document.getElementById('fname-input').focus();
        formIsValid = false;
        return formIsValid;
      }
    }
    // if(fields.lname == ''){
    //   setError("*Please Enter Your Last Name");
    //   document.getElementById('lname-input').focus();
    //   formIsValid = false;
    //   return formIsValid;
    // }
    // if(fields.lname.length != ''){
    //   if(fields.lname.length < 3){
    //     setError("*Please Enter Your Last Name between 4 to 8 characters");
    //     document.getElementById('lname-input').focus();
    //     formIsValid = false;
    //     return formIsValid;
    //   }
    //  }
   
    if(fields.email == ''){
      setError("*Please Enter Your Email");
      document.getElementById('email-input').focus();
      formIsValid = false;
      return formIsValid;
    }
    if (fields.email !='' && Emailregex.test(fields.email) === false) {
      setError("Please Enter Valid Email");
      document.getElementById('email-input').focus();
      formIsValid = false;
      return formIsValid;
    }
    if(fields.phone == ''){
      setError("*Please Enter Your Phone Number");
      document.getElementById('phone-input').focus();
      formIsValid = false;
      return formIsValid;
    }
    if(fields.phone.length < 8){
      setError("*Incorrect Phone Number. Use ISD code with number");
      document.getElementById('phone-input').focus();
      formIsValid = false;
      return formIsValid;
    }
    if(fields.message == ''){
      setError("*Please Enter Your Message");
      document.getElementById('message-input').focus();
      formIsValid = false;
       return formIsValid;
    }
    return formIsValid = true;
  }
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc="https://aurumfiles.b-cdn.net/contact_new.png" />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form action={formAction} method={formMethod}>
              <Input id="fname-input" type="text" name="fname" placeholder="Your Name" required/>
              <Input id="email-input" type="email" name="email" placeholder="Business Email Address" required/>
              <Input id="phone-input" type="number" name="phone" placeholder="Contact Number" required/>
              <Textarea id="message-input" name="message" placeholder="How can we help you ?"/>
              <Input id="fromPage-input" type="hidden" name="fromPage" value="{fromPage}"/>
              
              <SubmitButton type="button" onClick={sendLogin}>{submitButtonText}</SubmitButton>
            </Form>
            <ErrorLabel>{msgVal}</ErrorLabel>  
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
