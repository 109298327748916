import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { serverUtilities } from "services/serverutilities.service";

const TableContainer = styled.div`
  ${tw`overflow-x-auto mt-8 mx-8`}
`;

const Table = styled.table`
  ${tw`min-w-full px-10 bg-white border border-gray-300`}
  border-collapse: collapse;
`;

const TableHead = styled.thead`
  ${tw`bg-gray-100`}
`;


const TableHeading = styled.th`
${tw`text-left py-3 px-4 font-semibold text-gray-600 uppercase`}
`;

const TableBody = styled.tbody``;
const TableData2 = styled.td`
${tw`py-3 px-4 text-gray-700`}
width: 200px; /* Set a fixed width */
word-wrap: break-word; /* Ensures long words break and wrap */
white-space: normal; /* Allows text to wrap onto the next line */
overflow-wrap: break-word; /* Breaks long words if necessary */
`;

const TableRow = styled.tr`
  ${tw`border-b border-gray-200`}
`;
const TableData = styled.td`
  ${tw`py-3 px-4 text-gray-700 `}
  width: 400px; /* Set a fixed width */
  word-wrap: break-word; /* Ensures words wrap properly */
  white-space: normal; /* Allows multi-line text */
`;

const TableData1 = styled.td`
  ${tw`py-3 px-4 text-gray-700 flex items-center space-x-2`}
`;



const Button = styled.button`
  ${tw`px-4 py-2 rounded text-white mt-4 focus:outline-none focus:ring-2 focus:ring-offset-2`}
  ${({ variant }) =>
    variant === "edit"
      ? tw`bg-blue-500 hover:bg-blue-600 focus:ring-blue-400`
      : variant === "view"
      ? tw`bg-green-500 hover:bg-green-600 focus:ring-green-400`
      : tw`bg-gray-700 hover:bg-gray-800 focus:ring-gray-500`}
`;

const BlogTable = () => {
  const [visible, setVisible] = useState(1);
  const [blogData, setBlogData] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const url = window.location.href;
  let spliturl = url.split("/").pop();
//   if (spliturl === "")
     spliturl = "blog";

 
  useEffect(() => {
    if (spliturl) {
      serverUtilities.getBlogList(spliturl, visible, 'blogfeed').then((resData) => {
        console.log("resData333333333", resData);

        
        if (resData.article_list.length > 0) {
          setBlogData(resData.article_list);
        } else {
          setHasMore(false); 
        }
      });
    }
  }, [visible, spliturl]);

  const loadMore = () => {
    setVisible((prev) => prev + 1);
  };

  const handleEdit = (slug) => {
    if (slug) {
      window.open(`https://youngminds.pro/blog-feed?blogid=${slug}`, '_blank');
    }
  };
  const handleView = (item) => {
    if (item) {
      window.open(
        "https://youngminds.pro/" +
        item.categ.toLowerCase() +
        "/" +
        item.slug, '_blank');
      // window.location.href = `https://youngminds.pro/blog-feed?slug=${slug}`;
    }
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeading style={{ width: "100px" }}>ID</TableHeading>
            <TableHeading style={{ width: "100px" }}>Date</TableHeading>
            <TableHeading style={{ width: "300px" }}>Title</TableHeading>
            <TableHeading style={{ width: "300px" }}>Description</TableHeading>
            <TableHeading style={{ width: "200px" }}>Actions</TableHeading>
          </TableRow>
        </TableHead>
        <TableBody>
          {blogData.map((item) => (
            <TableRow key={item.id}>
              <TableData>{item.id}</TableData>
              <TableData>{item.date || "N/A"}</TableData>
              <TableData>{item.title}</TableData>
              <TableData>{item.descrip}</TableData>
              <TableData1>
              <Button
                  variant="edit"
                  onClick={() => handleEdit(item.id)}
                  
                >
                  Edit
                </Button>
                <Button
                  variant="view"
                  onClick={() => handleView(item)}
                  style={{ marginLeft: "8px" }}

                >
                  View
                </Button>
              </TableData1>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {hasMore && (
        <Button variant="load" onClick={loadMore}>
          Load More
        </Button>
      )}
    </TableContainer>
  );
};

export default BlogTable;
