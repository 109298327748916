import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainBanner from "components/hero/AurumHeroContent.js";
import BlogMainContent from "components/hero/BlogMainContent.js";
import Stats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import OurExpertise from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
import TrackRecord from "components/features/TwoColSingleFeatureWithStats2.js";
import Faqs from "components/faqs/SimpleWithSideImage.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as Checkicon } from "../images/check.svg";
import BlogSummary from "components/cards/BlogSummary.js";
import BlogAss from "components/cards/blog_assessment.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import caseStudyLogoImage from "images/clientLogos/languagePantheon.png";
import caseStudyProfileImage from "images/lp_anuj_k_acharya.png";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useParams,
  useHistory,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { serverUtilities } from "../services/serverutilities.service";
import axios from "axios";
import BlogTable from "./blogTable";
const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-brand-500`;
const HighlightedText = tw.span`text-brand-500 `;

const BoldTag = styled.span`
  ${tw`text-textBlack font-bold`}
`;
  let self_assessment = 0;
export default ({
  title = "Simplified On-Boarding",
  highlightedtext = "Get Started.",
  heading = "Easy to",
  CheckIcon = Checkicon,
}) => {
  const urlI = window.location.href;
  const history = useHistory();
  //////////////////console.log('urllllllllllll',url);
  //alert(url);
  let urlF = urlI.split("?");
  const url = urlF[0].replace('--ym', '');

  let spliturl = url.split("/").pop();

  const urlgerman = url;

  const splitPart = urlgerman.split("/")[3]; // "german-language"
  let titleBlog = spliturl.replace(/-/g, " ");
  titleBlog = titleBlog.replace(/\b\w/g, (x) => x.toUpperCase());
  //alert(spliturl);
  //////////////////console.log('splitPart',splitPart);
  const [blogData, setBlogData] = useState("0");
  const [relatedblogData, setrelatedBlogData] = useState("0");
  const [blogProds, setBlogProds] = useState("0");
  const [blogBanners, setBlogBanners] = useState("0");
  let vals = "0";
  let slug = url.name;


  if (url.indexOf('self-assessment')>0)
    self_assessment = 1;
  let URI = "https://duxyz.b-cdn.net/0/" + spliturl;
  URI = "https://youngminds.pro/tt/" + spliturl;

  const requestOptions = {
    method: "GET",
    headers: {},
  };
  //const res = axios.get(URI);
  //////////////////console.log(res);

  // let blogData = res.data.vals;
  // ////////////////console.log("blogData==",blogData)
  // let relatedblogData = res.related;
  //////////////////console.log("res==", res);
  //let blogData = '0';
  //let relatedblogData = '0';

  //alert ("1");
  /*let slug = url.name;*/
  useEffect(() => {
    //if (spliturl != undefined && spliturl != "") 


    if (blogData == "0") {
    const res = axios.get(URI);
    ////////////////console.log('resssssssss',res);
    const bD = res.then((data) => {
      ////////////////console.log(data);
      
      if (data.data.status == '0')
      {
          //const navigate = useNavigate();
          
          history.push("/404");
          return;
      }
      setBlogData(data.data.vals);
      setrelatedBlogData(data.data.related);
      //blogData = data.data.vals;
      //relatedblogData = data.data.related;
      ////////////////console.log(data.data.related);
      let v = data.data.vals[0].id + "--" + data.data.vals[0].keywords.replace(" ","-");
      v = v.toLowerCase();
      

      //COMMENTED TO DIABLE PRODUCTS AND BANNERS FOR THE TIME BEING
      /*serverUtilities.getBlogProds(v.toString()).then((resData) => {
        ////////////////console.log(resData);
        //////////////////console.log(resData.vals.vals);
        if (resData) {
          setBlogProds(resData.prods);
          //titleBlog = resData.vals.title;
          setBlogBanners(resData.banners);
          //val = resData.status;
        }
      });
     */
      
    });
    ////////////////console.log(blogData);
  }

    
  }, []);
  // ////////////////console.log(blogData);
  //////////////////console.log(vals);
  // if ((vals == '0') && (blogData!=null))
  // {}
  // else
  return (
    
    blogData.length > 0 && blogData != "0" &&
    relatedblogData != "0" && (
      <>
        
        <AnimationRevealPage>
          <BlogMainContent
            roundedHeaderButton={true}
            mainTitle={blogData != null && blogData[0].title}
            subTitle={blogData != null && blogData[0].descrip}
            blogBanners={blogBanners != null && blogBanners}
            buttonText="Study Plan for May 2025"
            buttonUrl="https://youngminds.pro/study-plan/ca-foundation-accounts-for-may-2025"
            imageSrc={
              blogBanners != null && blogBanners.header_img
                ? blogBanners.header_img
                : "https://ik.imagekit.io/zjste1wkulv/tr:q-90/content_delivery_home_new.gif"
            }
            showClient={false}
            header={splitPart && splitPart}
          />
          {self_assessment == 1 ? (
            <BlogAss
            heading="Foeign Language Courses"
            subheading=""
            description="Everything You Need to Deliver & Monetise Content "
            data={blogData != null && blogData[0].html}
            relatedData={relatedblogData != null && relatedblogData}
            blogProds={blogProds != null && blogProds}
            blogBanners={blogBanners != null && blogBanners}
            blog_id={blogData != null && blogData[0].id}
            allBlogData={blogData != null && blogData[0]}
          />
            ) : (
          
          <BlogSummary
            heading="Foeign Language Courses"
            subheading=""
            description="Everything You Need to Deliver & Monetise Content "
            data={blogData != null && blogData[0].chunks}
            relatedData={relatedblogData != null && relatedblogData}
            blogProds={blogProds != null && blogProds}
            blogBanners={blogBanners != null && blogBanners}
            blog_id={blogData[0].id}
          />
          
          
        )}
        {/* <BlogTable blogData={blogData} /> */}
          <PreFooter
            fromPage="BlogPage"
            formheading="Get in Touch With Our Academic Counsellors to Help You In Your Preparations."
            formsubheading="Book Our Free Discussion Session Today"
            heightlightText=""
          />
          <Footer />
        </AnimationRevealPage>
      </>
    )
  );
};
