import { useEffect } from "react";
import { Helmet } from "react-helmet";

const NotFound = () => {
  useEffect(() => {
    document.title = "404 - Page Not Found";
    window.history.replaceState({}, "", "/404"); // Ensures URL remains 404
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div>
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
      </div>
    </>
  );
};

export default NotFound;
