import React from 'react';

const MetricResult = ({ value, maxValue }) => {
  // Calculate percentage for the progress bar
//   const percentage = Math.min((value / maxValue) * 100, 100);
const percentage = Math.min(Math.round(100*(value/maxValue)), 100);
  
  return (
    <div className="resultBox">
      <div 
        className="skills"
        style={{
          width: `${percentage}%`,
          height: '10px',
          backgroundColor: '#5e7aa8',
          borderRadius: '5px',
          transition: 'width 0.5s ease-in-out',
          position: 'relative'
        }}
      >
        <span 
          className="value-label"
          style={{
            position: 'absolute',
            right: '0',
            top: '-20px',
            fontSize: '12px',
            color: '#333'
          }}
        >
          {/* {value}% */}
        </span>
      </div>
    </div>
  );
};

export default MetricResult;