import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

/*import LogoImage from "../../images/logo.svg";*/
import logo from "../../images/youngmindslogo.png";
import StartupLogo from "../../images/fixed-startup-india-logo.png";
import { components } from "ComponentRenderer.js";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as LinkedinIcon } from "../../images/linkedin-icon.svg";

const Container = tw.div`relative bg-[#131a22] -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 lg:flex lg:flex-row justify-between xs:flex xs:flex-col`;

const Column = tw.div`mt-5 md:w-1/6`;
const WideColumn = tw(Column)`text-center md:text-left lg:w-1/4 md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold text-white`;

const LinkList = tw.ul`mt-4 text-sm font-medium `;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent text-white hocus:text-white hocus:border-white pb-1 transition duration-300`;
const LinkB = tw.a`ml-5 border-b-2 border-transparent text-white hocus:text-white hocus:border-white pb-1 transition duration-300 first:ml-0 `;
const LinkC = tw.a`border-b-2 border-transparent text-white hocus:text-blackStep-100 hocus:border-blackStep-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;

const AlIngenious= tw.h1`mt-2 text-lg font-bold text-white`;
const LogoImg = tw.img`w-1/2`;
const StartupImg = tw.img`w-1/2 py-4`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 text-white`;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-linkedin text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default ({
  landingPages = components.landingPages,
  innerPages = components.innerPages,
}) => {

  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src="https://aurumfiles.b-cdn.net/youngmindslogo.png" className="aurum-logo" />
          </LogoContainer>
          <SocialLinksContainer>
            <SocialLink href="https://www.linkedin.com/showcase/young-minds-pro/">
              <LinkedinIcon />

            </SocialLink>

          </SocialLinksContainer>
          <CompanyDescription>
            
            
          </CompanyDescription>
        </WideColumn>
        <Column>
          <ColumnHeading>CA Foundation</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href={innerPages.cafoundationaccounts.url}>Accounts</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.cafoundationaccounts.url}>Mathematics</Link>
            </LinkListItem>
          <LinkListItem>
              <Link href={innerPages.cafoundationaccounts.url}>Economics</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.cafoundationaccounts.url}>Law</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.cafoundationaccounts.url}>Previous Years</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.cafoundationaccounts.url}>Mock Tests</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        
        <Column>
          <ColumnHeading>Company</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="https://youngminds.pro/ca-foundation/why-youngminds-is-best-for-your-preparation-for-ca-foundation-2025">Why Young Minds</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.blog.url}>Blogs</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.contactus.url}>Contact Us</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.policycenter.url}>Policy Center</Link>
            </LinkListItem>

          </LinkList>
        </Column>
        <CompanyDescription>
         <ColumnHeading>Argentum Labs Private Limited</ColumnHeading>
          <StartupImg src={StartupLogo} className="" />
          DEPARTMENT FOR PROMOTION OF INDUSTRY AND INTERNAL TRADE <br/> MINISTRY OF COMMERCE AND INDUSTRY GOVERNMENT OF INDIA <br/><br/> Registration No. DIPP97530
        </CompanyDescription>
      </FiveColumns>
    </Container>
  );
};
