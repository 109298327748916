import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import AnimatedText from "./AnimatedText.js";
import Header from "../headers/light.js";
import NewHeader from "../headers/headers.js";
import OurClients from "../clients/Clients.js";
// import { Link } from "react-router-dom/cjs/react-router-dom.min.js";

const Container = tw.div`relative bg-[#fdedf6] lg:px-0 xs:px-2 lg:py-0 md:py-20 sm:py-24`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto xs:py-24 md:py-10 lg:py-0`;
const LeftColumn = tw.div`relative lg:w-6/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;
const Link = tw.a`inline-block mt-2 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;
const Heading = tw.h1`font-bold text-4xl md:text-4xl lg:text-4xl xl:text-3xl text-black leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md mx-auto lg:mx-0`}
  button {
    ${tw`w-8/12 bg-secondary-600 text-gray-100 xs:m-auto lg:ml-0 lg:mr-2 my-4 sm:my-2 py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-secondary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

const ClientSection = tw.section`text-center mt-5 mb-10`;

export default ({
  roundedHeaderButton,
  mainTitle = "",
  subTitle = "",
  imageSrc = "",
  buttonText = "",
  buttonUrl = "https://wa.me/918826622806/?text=Hi, How can i GROW my Digital Content Business with Aurum",
  showClient = true,
  blogBanners,
  header,
}) => {
  //////console.log("blogprodsssssss", header);
  return (
    <>
      {header == "german-language" ? (
        <Header roundedHeaderButton={roundedHeaderButton} />
      ) : (
        <NewHeader />
      )}
      <Container>
        <TwoColumn>
          <LeftColumn>
            {mainTitle && <Heading>{mainTitle}</Heading>}
            {subTitle && <Paragraph>{subTitle}</Paragraph>}
            {buttonText != "0" && (
            <Actions>
              <a href={buttonUrl} target="_blank">
                <button className="bt-round" style={{ width: "80%" }}>{buttonText}</button>
              </a>
            </Actions>
            )}
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <Link href={blogBanners.header_url} target="_blank">
                <img
                  tw="min-w-0 w-full max-w-xs xl:max-w-xs "
                  src={imageSrc}
                  alt="Design Illustration"
                />
              </Link>
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        {showClient == true && (
          <>
            <AnimatedText />
            <ClientSection>
              <OurClients />
            </ClientSection>
          </>
        )}
      </Container>
    </>
  );
};
