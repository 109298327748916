import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
// import {BrowserRouter as Router, Switch, Route, Link, useParams} from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useParams,
} from "react-router-dom";
import Footer from "components/footers/SimpleFiveColumn.js";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import { components } from "ComponentRenderer.js";
import { serverUtilities } from "../services/serverutilities.service";
const HeadingRow = tw.div`flex mt-24 ml-10 lg:mt-0 `;
const HeadingN = tw.div`text-gray-900 text-3xl lg:text-3xl  font-bold`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
//${tw`w-1/2!`}
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-full lg:w-full sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg hover:shadow-md`;
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;
const Link = tw.a`border-b-2 border-transparent text-black hocus:text-brand-500 hocus:border-brand-500 pb-1 transition duration-300`;
export default ({
  innerPages = components.innerPages,
  headingText = "Knowledge Center",
  posts = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1499678329028-101435549a4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
      category: "Travel Tips",
      date: "April 21, 2020",
      title: "Safely Travel in Foreign Countries",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      url: "http://localhost:3000/lp-case-study",
      featured: true,
    },
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
  ],
}) => {
  const [visible, setVisible] = useState(1);
  const onLoadMoreClick = () => {
    setVisible((v) => v + 1);
  };

  const url = window.location.href;
  //////console.log(url);
  //alert(url);
  let spliturl = url.split("/").pop();
  if (spliturl == "") spliturl = "blog";
  //alert(spliturl);
  //////console.log(spliturl);
  const [blogData, setBlogData] = useState(null);
  //alert ("1");
  /*let slug = url.name;*/
  //////console.log("visible", visible);
  useEffect(() => {
    if (spliturl != undefined && spliturl != "") {
      serverUtilities.getBlogList(spliturl, visible).then((resData) => {
        //////console.log('resData',resData);
        if (resData.article_list.length > 0) {
          setBlogData(resData.article_list);
        }
      });
    }
  }, [visible]);
  return (
    <AnimationRevealPage >
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow >
            <HeadingN>{headingText}</HeadingN>
          </HeadingRow>
          <Posts>
            {blogData != null &&
              blogData.map((post, index) => (
                <PostContainer key={index} featured={post.categ}>
                  <Post
                    className="group"
                    as="a"
                    href={
                      "https://youngminds.pro/" +
                      post.categ.toLowerCase() +
                      "/" +
                      post.slug
                    }
                  >
                    <Info>
                      <Category>{post.categ}</Category>
                      <CreationDate>{post.date}</CreationDate>
                      <Title>{post.title}</Title>
                      <Description>{post.descrip}</Description>
                    </Info>
                  </Post>
                </PostContainer>
              ))}
          </Posts>

          {blogData != null && (
            <>
              {visible < blogData.length && (
                <ButtonContainer>
                  <LoadMoreButton onClick={onLoadMoreClick}>
                    Load More
                  </LoadMoreButton>
                </ButtonContainer>
              )}
            </>
          )}
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

const getPlaceholderPost = () => ({
  imageSrc:
    "https://images.unsplash.com/photo-1418854982207-12f710b74003?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  category: "Travel Guide",
  date: "April 19, 2020",
  title: "Visit the beautiful Alps in Switzerland",
  slug: "Visit_the_beautiful_Alps_in_Switzerland",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "",
});
