import React from 'react';
import './InlineSuggestion.css';

function InlineSuggestion({ heading, link, text, className }) {
	console.log("Passed className:", className);
  // Combine base class + additional modifiers
  return (
    <div className={`inline-suggestion ${className ? className : ''}`}>
      <span className="inline-suggestion__title">{heading}</span>
      <a href={link}>{text}</a>
    </div>
  );
}

export default InlineSuggestion;
