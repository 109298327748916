import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; 
// import { ReactComponent as Hearing } from "images/hearing.svg";
import Lpimage from "../../images/clientLogos/languagePantheon.png"
import Gradeup from "../../images/clientLogos/gradeup.png"
import Iamsnext from "../../images/clientLogos/iamsnext.svg"
import Uniqueshiksha from "../../images/clientLogos/uniqueshiksha.png"
import Kiranprakashan from "../../images/clientLogos/kiranprakashan.png"
import DBMI from "../../images/clientLogos/dbmi.png"
import Igp from "../../images/clientLogos/igp.png"
import Hearing from "../../images/hearing.svg"
import Reading from "../../images/reading.svg"
import Speak from "../../images/speaker.png"
import { SectionHeading } from "components/misc/Headings.js";
// import Igiaviation from "../../images/clientLogos/igiaviationdelhi.png"
import { useSpeechSynthesis,useSpeechRecognition  } from 'react-speech-kit';
const Section = styled.div`
	${tw`relative xs:px-10 py-20 md:py-10 bg-white`}
`;
const Container = styled.div`
	${tw`relative text-left max-w-[1280px] mx-auto`}
	.radioSec{ 
		${tw`flex  gap-6 md:ml-[28px] mt-10 w-[280px] md:w-[300px] text-left bg-purple-250 py-2 md:py-4`}
	}
	.inputBox{
		${tw`mx-auto `}
	}
	.inputBox label{
		${tw`ml-2 text-white font-semibold text-xl`}
	}
	p{
		${tw`text-blackStep-100 font-normal md:font-semibold text-base md:text-lg my-4`}
	}
	.quizSec{
		${tw` bg-whiteSmoke p-4`}
	}
	.quizSec h3{
		${tw`text-left mb-4 font-semibold text-2xl text-blackStep-200`}
	}
	.radioBottomSec{
		${tw`flex  gap-4 md:gap-10 w-full  md:w-[400px] text-left py-2 md:py-4`}
	}
	.inputQuizBox{
		${tw`mx-auto`}
	}
	.inputQuizBox label{
		${tw`ml-2 text-blackStep-100 text-sm`}
	}
	.radioBottomBlock{
		${tw`flex flex-col gap-1 justify-start md:flex-row w-[250px] md:w-full md:gap-10 md:justify-between`}
	}
	.errorBlock{
		${tw`w-full md:w-[600px] text-center`}
	}
	.fail{
		${tw`text-red-600`}
	}
	.success{
		${tw`text-green-600`}
	}

`;
const IconContainer = tw.i``;
const Heading = tw(SectionHeading)`w-full  mb-20 max-w-[1170px] mx-auto`;

const TextBox = styled.div`
	${tw`relative text-left w-full md:w-1/2 max-w-[1280px]  border border-gray-300  rounded`}
	.innerSec{ 
		${tw`text-center px-10 py-10`}
	}
	.para{
		${tw`text-left px-4 py-4 md:px-10 md:py-10 text-2xl leading-8 text-blackStep-200 font-normal`}
	}
`;
const SpeakBox = styled.div`
	${tw`relative text-center md:text-left w-full md:w-1/4 max-w-[1280px]`}
	p{
		${tw`text-center md:text-left text-sm mb-2 font-normal md:font-semibold`}
	}
	.speakSec{
		${tw`flex flex-col md:flex-row items-center gap-2`}
	}
	.speakSec p{
		${tw`text-center md:text-left text-base font-normal md:font-semibold text-blackStep-200`}
	}
	.underlineText p{
		${tw`text-center md:text-left text-base font-normal md:font-semibold text-blackStep-200 mt-2 hover:underline`}
	}
`;
const InnerBlock = styled.div`
	${tw`flex flex-col md:flex-row w-full max-w-[1280px] gap-6`}
`;
export default ({
	heading = "Enhance Your Hearing Skills",

}) => {
const englishPara = 'My family includes four people. My mother is me and of course my husband is one of them. We have two children, a son who is six years old and a three year old daughter.';
const germanPara = 'Zu meiner Familie gehören vier Personen. Die Mutter bin ich und dann gehört natürlich mein Mann dazu. Wir haben zwei Kinder, einen Sohn, der sechs Jahre alt ist und eine dreijährige Tochter.';
	const [paraText,setParaText] = useState(germanPara);
  const { speak, cancel, voices } = useSpeechSynthesis();
  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      setParaText(result);
    },
  });
  const [selectedOption,setSelectedOption] = useState('german');
  const [selectedQuizOption,setSelectedQuizOption] = useState('');
  
  const [show,setShow] = useState(false);
 const onValueChange =(e)=>{
 	//////console.log(e.target.value);
 	setSelectedOption(e.target.value);
 	if(e.target.value=='english'){
 		setParaText(englishPara);
 	}else{
 		setParaText(germanPara);
 	}
 	
 }
 const handleQuiz =(e)=>{
 	//////console.log(e.target.value);
 	setSelectedQuizOption(e.target.value);
 	
 }
 const handleSpeak =()=>{
 	////////console.log(voices);
 	////////console.log(voices);
 	////////console.log(selectedOption);
 	if(show==false){
 		if(selectedOption == 'english')
 			speak({ text: paraText, voice: voices[1] });
 		else
 			speak({ text: paraText, voice: voices[5] });
		setShow(!show);
 	}else{
 		cancel();
		setShow(!show);
 	}
	
 }
 const handleSpeakStop =()=>{
 	cancel();
	setShow(!show);
 }
  return (
  	<Section>
	    <Container>
	    	<Heading>{heading}</Heading>
			<div className="toolitem">	
				<div className="radioSec">
		      		<div className="inputBox">
			            <input
			              type="radio"
			              value="german"
			              checked={selectedOption === "german"}
			              onChange={onValueChange}
			            />
			            <label>German</label>
			        </div>
			        <div className="inputBox">
			            <input
			              type="radio"
			              value="english"
			              checked={selectedOption === "english"}
			              onChange={onValueChange}
			            />
			            <label>English</label>
			        </div>
		        </div>
			</div>
			<InnerBlock>
				<TextBox className="innerSec">
					<p className="para">{paraText}</p>
				</TextBox>
				<SpeakBox >
					<p>Speaking word :</p>
					<a onClick={() => handleSpeak()} href="javascript:void(0)" className="speakSec">
						<img src={Speak} />
						{show==false &&(<p>Listen the Paragraph</p>)}
					</a>
					{show==true &&(
						<a onClick={() => handleSpeakStop()} href="javascript:void(0)" className="underlineText">
							<p>Stop Hearing</p>
						</a>
					)}
					
				</SpeakBox>
			</InnerBlock>
			<p className="">You understood the above text?</p>
			<div className="quizSec">
				<h3>Wie viele Personen hat die Familie?</h3>
				<div className="radioBottomBlock">
					<div className="radioBottomSec">
			      		<div className="inputQuizBox">
				            <input
				              type="radio"
				              value="3"
				              checked={selectedQuizOption === "3"}
				              onChange={handleQuiz}
				            />
				            <label>3</label>
				        </div>
				        <div className="inputQuizBox">
				            <input
				              type="radio"
				              value="4"
				              checked={selectedQuizOption === "4"}
				              onChange={handleQuiz}
				            />
				            <label>4</label>
				        </div>
				        <div className="inputQuizBox">
				            <input
				              type="radio"
				              value="5"
				              checked={selectedQuizOption === "5"}
				              onChange={handleQuiz}
				            />
				            <label>5</label>
				        </div>
				        <div className="inputQuizBox">
				            <input
				              type="radio"
				              value="6"
				              checked={selectedQuizOption === "6"}
				              onChange={handleQuiz}
				            />
				            <label>6</label>
				        </div>
			        </div>
			        {selectedQuizOption!='' &&(
				        <div className="errorBlock">
				        	{selectedQuizOption =='4'  ? <p className="success">Your answer is correct.</p>:
				        	<p className="fail">Ops! your answer is wrong!</p>}
				        </div>
			    	)}
		        </div>
			</div>
	    </Container>
	</Section>
  );
};
