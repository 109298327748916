// import React from "react";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import BlogFeed from "pages/BlogFeed";
import RelatedArticle from "../blogs/ThreeColSimpleWithTopImage.js";
import { Timer, CheckCircle2, XCircle } from "lucide-react";
import QuizProgressChart from "./quizprogresschart.js";
import md5 from "md5";
import LoginModal from "pages/loginModal.js";
import ThreeCharts from "./chart.js";
import { useHistory, useLocation } from "react-router-dom";
import ResultScreen from "./resultscreen.js";
import MetricResult from "./matrixresult.js";

const Container = tw.div`relative bg-white lg:px-0 xs:px-2`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-row items-center md:items-stretch md:flex-row flex-wrap md:justify-start py-10 md:py-4`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full text-left`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-3/4`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-black leading-loose`}
  }
`;
const ParaBlock = styled.div`
  ${tw`w-full mb-6`}
`;
const Title = styled.h4`
  ${tw`text-xl font-bold text-gray-900 text-left`}
`;

const QuizContainer = styled.div`
  ${tw`w-full mx-auto p-6 bg-white rounded-lg shadow-md`}
`;

const QuestionContainer = styled.div`
  ${tw`mb-6`}
`;

const QuestionTitle = styled.h2`
  ${tw`text-xl font-semibold text-gray-900 mb-4`}
`;

const OptionsContainer = styled.div`
  ${tw`space-y-1`}
`;

const OptionLabel = styled.label`
  ${tw`flex items-center p-3 bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200`}
`;
const QuestionText = styled.h3`
  ${tw`text-xl font-semibold text-gray-900 mb-4`}
`;

const OptionsList = styled.div`
  ${tw`flex flex-col space-y-1`}
`;

// const OptionLabel = styled.label`
//   ${tw`flex items-center bg-gray-200 p-4 rounded-lg cursor-pointer hover:bg-gray-300`}
//   width: 90%;
// `;

const OptionCheckbox = styled.input`
  ${tw`mr-4`}
`;

const ButtonContainer = tw.div`flex space-x-4 mt-6`;

/*const ActionButton = styled.button`
  ${tw`mt-6 px-4 py-2 text-white rounded-md`}
  background-color: #d9328c;
  &:hover {
    background-color: #b1256f;
  }
`;*/

const ActionButton = styled.button`
  ${tw`mt-6 text-white font-bold py-2 px-4 rounded`}
  width : 200px;
  height: 50px;
  background-color: ${({ bgColor }) => bgColor || "#587cb6"};

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  &:hover {
    /* Optionally define a hover state */
    background-color: "#49689a";
  }
`;

const TimerContainer = styled.div`
  ${tw`flex items-center justify-between px-3 mb-4 mt-5`}
`;
const SolutionContainer = styled.div`
  ${tw`mt-6 space-y-4`}
`;

const AnswerStatus = styled.div`
  ${tw`flex items-center gap-2`}
`;

const SolutionBox = styled.div`
  ${tw`bg-gray-500 p-4 rounded`}
`;
const FillInBlankInput = styled.input`
  ${tw`p-3 border rounded-lg`} /* Removed w-full to avoid conflict */
  width: 90%;
  margin: 0 auto; /* Centers the input horizontally */
`;

// const ButtonContainer = tw.div`flex space-x-4 mt-6`;
// const ActionButton = styled.button`
//   ${tw`px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-700`}
// `;
const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const LLOADER = styled.div`
  display: inline-block;
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
const Paragraph = styled.div`
  ${tw`w-full text-lg leading-loose text-gray-600 font-medium`}
  .blogContainer {
    ${tw`lg:pr-10 px-2`}
  }
  html {
    font-size: 14px; /* 1rem = 13px */
  }
  h1 {
    ${tw`mt-10 mb-2 leading-tight !text-[#f20e80]`};
    font-size: 1.5rem;
  }
  h2 {
    ${tw`mt-10 mb-2 leading-tight`};
    color: #597db6;
    font-size: 1.2rem;
    font-weight: bold;
  }
  h3 {
    ${tw`mt-2 mb-4 leading-tight`};
    color: #0b1320;
    font-size: 1.2rem;
    font-weight: bold;
    border-bottom: 2px solid #0b1320;
    padding-bottom: 5px;
  }
  h4 {
    ${tw`mt-2 mb-4 !text-black leading-tight`};
    color: #0b1320;
    font-size: 1.2rem;
  }
  h5 {
    ${tw`mt-2 mb-4 !text-black leading-tight`};
    color: #0b1320;
    font-size: 1rem;
  }
  h6 {
    ${tw`mt-2 mb-4 !text-black leading-tight`}
    color : #0b1320;
    font-size: 1rem;
  }
  ul {
    list-style: disc;
    margin-left: 19px;
    width: 90%;
    margin-top: 5px;
    margin-bottom: 20px;
  }
  li {
    color: #0b1320;
    list-style: none;
    font-weight: 500;
    position: relative;
    padding-left: 15px;
  }
  ul li::before {
    content: "*";
    position: absolute;
    top: 6px;
    left: 0;
  }
  .blogContainer p {
    ${tw`mb-4 !text-[#191C1F] leading-7 text-justify`}
  }
  .blogContainer strong {
    ${tw`!font-semibold !text-black`}
  }
  .blogContainer span {
    ${tw`font-bold !text-brand-500 text-lg`}
  }
  .blogContainer img {
    ${tw`w-auto my-6`}
  }
  table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #dbdbdb;
    border-radius: 5px !important;
    margin-bottom: 15px;
  }
  thead {
    background: #22242d;
  }
  thead tr td p b {
    ${tw`text-[#fff]`}
  }

  th,
  td {
    text-align: left;
    padding: 8px;
    border-left: 1px solid #dbdbdb;
    padding-left: 15px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  ol {
    display: block;
  }
  ol li {
    padding: 7px 15px;
    font-size: 1.125rem;
    font-weight: 500;
    background: #fafafa;
    color: #f49868;
  }
`;

const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto lg:py-2 xs:py-2 md:py-2 sm:py-2`;
const LeftColumn = tw.div`relative lg:w-[75%] text-left max-w-lg pr-6 lg:max-w-none lg:text-left lg:self-start`;
const RightColumn = tw.div`relative p-5 bg-[#f5f5f5] mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-start`;
/*const RightColumn = styled.div`
  ${tw`bg-[#f5f5f5] md:w-1/2 lg:w-1/4 py-4 px-8`}
`;
*/
const Sectionhead = styled.h2`
  ${tw`!text-xl font-semibold text-gray-900 text-left mb-6`}
`;
const LinkBlock = styled.div`
  ${tw`flex flex-col space-y-4 `}
`;
const Link = styled.a`
  ${tw`text-[#121a23] border-b-4 leading-loose`}
`;
const LoginModalopen = ({ onClose, onSuccessfulLogin }) => {
  return (
    <div tw="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto p-4">
      <div tw="bg-white w-[50vw] max-h-[80vh] rounded-lg relative overflow-y-auto">
        <button
          tw="absolute top-0 right-0 m-4 text-gray-600 hover:text-red-900"
          onClick={onClose}
        >
          Close ×
        </button>
        <div tw="p-6">
          <LoginModal onSuccessfulLogin={onSuccessfulLogin} />
        </div>
      </div>
    </div>
  );
};

export default ({
  data,
  blog_id,
  relatedData,
  allBlogData,
  cards = null,
  blogProds,
  blogBanners,
  heading = "Our Offices",
  subheading = "Locations",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
}) => {
  ////////console.log(data);

  const defaultCards = [
    {
      title: "Branding",
      description:
        "Need to engage your audience? Download incredible Social Media Illustrations to showcase powerful images for free. From online interactions and social apps to user reactions, have a look at our designs and bring these graphics to life with our animation features!",
    },
    {
      title: "Scalable",
      description:
        "Need to engage your audience? Download incredible Social Media Illustrations to showcase powerful images for free. From online interactions and social apps to user reactions, have a look at our designs and bring these graphics to life with our animation features!",
    },
    {
      title: "Reliable",
      description:
        "Need to engage your audience? Download incredible Social Media Illustrations to showcase powerful images for free. From online interactions and social apps to user reactions, have a look at our designs and bring these graphics to life with our animation features!",
    },
    {
      title: "Engaging",
      description:
        "Need to engage your audience? Download incredible Social Media Illustrations to showcase powerful images for free. From online interactions and social apps to user reactions, have a look at our designs and bring these graphics to life with our animation features!",
    },
    {
      title: "Customizable",
      description:
        "Need to engage your audience? Download incredible Social Media Illustrations to showcase powerful images for free. From online interactions and social apps to user reactions, have a look at our designs and bring these graphics to life with our animation features!",
    },
    {
      title: "Fast",
      description:
        "Need to engage your audience? Download incredible Social Media Illustrations to showcase powerful images for free. From online interactions and social apps to user reactions, have a look at our designs and bring these graphics to life with our animation features!",
    },
  ];

  if (!cards) cards = defaultCards;
  /*{data &&(
              <div  dangerouslySetInnerHTML={{ __html: data }}></div>
            )}  */
  const keys = [
    "email",
    "hash",
    "userId",
    "name",
    "someId",
    "createdAt",
    "isActive",
    "phone",
    "flag1",
    "flag2",
    "expiryDate",
    "actionType",
    "flag3",
    "flag4",
    "flag5",
    "flag6",
  ];

  const [isStarted, setIsStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [timer, setTimer] = useState(0);
  const [attempts, setAttempts] = useState([]);
  const [topicId, setTopicId] = useState(null);
  const [userId, setUserId] = useState("0");
  const [TopicMatric, setTopicMatric] = useState(null);
  const [userMatric, setuserMatric] = useState(null);
  const [quesMatric, setquesMatric] = useState(null);
  const [checkedans, setcheckedans] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(1); // Initialize the index to 0
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [testError, settestError] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userMatric1, setuserMatric1] = useState({
    avg_acc: 0,
    avg_score: 0,
    avg_time: 0,
    min_ptile: 0,
    totalAttempts: 0,
  });

  const [nextqueArray, setnextqueArray] = useState([]);
  const [optionColor, setoptionColor] = useState(null);
  ////////console.log("attempts", attempts);
  const history = useHistory();
  const location = useLocation();

  const checkAuth = () => {
    const cookies = document.cookie.split(";");
    const userCookie = cookies.find((cookie) =>
      cookie.trim().startsWith("userLoginData=")
    );
    //////console.log("cookiesdataaa", userCookie);
    return !!userCookie;
  };
  // const checkAuth = () => {
  //   const cookies = document.cookie.split(';');
  //   const adminCookie = cookies.find(cookie => cookie.trim().startsWith('adminLoginData='));
  //   //////console.log('cookiesdataaa',adminCookie)
  //   return !!adminCookie;
  // };

  // API Configuration
  const API_KEY = "ymcaftgtbln";
  const API_SECRET = "poolsecret@#skk";
  const BASE_URL = "https://youngminds.pro/olv/mobapp/user";

  useEffect(() => {
    let interval;
    if (isStarted && !showAnswer) {
      interval = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isStarted, showAnswer]);

  // Generate Hash
  const generateHash = (time, timeExp) => {
    return md5(`${time}${API_KEY}${API_SECRET}jhakkas${timeExp}`);
  };

  // Start Test API Call
  const handleStartTest = async () => {
    const time = Math.floor(Date.now() / 1000);
    // const timeExp = '15'//time + 3600;
    const timeExp = time + 15;
    const hash = generateHash(time, timeExp);

    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(`${"userLoginData"}=`));
    //////console.log(cookies);
    //////console.log(cookie);
    const uid = cookie ? cookie.split("=")[1] : "0";
    //////console.log("uiddd", uid);

    const values = uid.split("|");
    const jsonObject = keys.reduce((acc, key, index) => {
      acc[key] = values[index] || null; // Handle cases where values are missing
      return acc;
    }, {});

    //////console.log(jsonObject);

    setUserId(jsonObject?.userId || uid);

    try {
      const response1 = await fetch(
        `${BASE_URL}/ymstarttest/${hash}/${time}/${timeExp}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            topic_id: allBlogData.topicid, // Hardcoded for now
            uid: jsonObject?.userId || "0", // Assuming user is not logged in
          }),
        }
      );
      //////console.log("resposense1", response1);
      const response = await response1.json();
      //////console.log("response", response);
      setuserMatric(response?.user_metrics);
      //setuserMatric1(response?.user_metrics);
      setTopicMatric(response?.topic_metrics);
      setquesMatric(response?.ques_metrics);
      if (response?.test_ques_arr == "")
        settestError(
          "Tests are Still Brewing ... Pls Try Later or Tomorrow..."
        );

      // const response = await axios.post(
      //   `${BASE_URL}/ymstarttest/${hash}/${time}/${timeExp}`,
      //   { uid }
      // );

      const { currentQues, topic_metrics, topicID, test_ques_arr } = response;
      //////console.log("test_ques_arr", test_ques_arr.split("--")); //test_ques_arr.split("--");
      setnextqueArray(test_ques_arr.split("--"));
      setCurrentQuestion(currentQues);
      setTopicId(topicID);
      setIsStarted(true);
    } catch (error) {
      console.error("Error starting test:", error);
      alert("Failed to start test. Please try again.");
    }
  };
  ////////console.log("nextqueArray", nextqueArray);
  const handleOptionSelect = (option) => {
    if (!isStarted) return;
    setSelectedOption(option);
  };

  // const handleViewAnswer = async () => {
  //   //////console.log("selectedoption", selectedOption);
  //   if (!selectedOption) return;

  //   const isCorrect = checkedans == currentQuestion.correctAns;
  //   //////console.log("hekckckck", checkedans, currentQuestion.correctAns);
  //   const status = isCorrect ? 1 : -1;

  //   setAttempts((prev) => [
  //     ...prev,
  //     {
  //       questionId: currentQuestion.questionId,
  //       timeTaken: timer,
  //       status,
  //       topic_id: currentQuestion.topicID,
  //     },
  //   ]);

  //   setShowAnswer(true);
  // };
  const handleViewAnswer = async () => {
    if (!selectedOption) return;

    const isCorrect = checkedans == currentQuestion.correctAns;
    const status = isCorrect ? 1 : -1;
    const newTime = timer;

    // //////console.log('checkedans',checkedans)
    // //////console.log('currentQuestion.correctAns;',currentQuestion.correctAns)

    // Store the attempt
    const newAttempt = {
      questionId: currentQuestion.questionId,
      timeTaken: newTime,
      status,
      topic_id: currentQuestion.topicID,
      option_selected: checkedans,
      qNo: attempts.length,
    };

    setAttempts((prev) => [...prev, newAttempt]);
    // //////console.log('lsjdlkfjslfjlsjflsjflsdjfldsjfldsjf',userMatric1)
    // //////console.log('iscorrect',isCorrect)
    // **Calculate New Averages**
    let updatedMatric = userMatric;
    //////console.log(updatedMatric);

    if (attempts.length == 0) updatedMatric.tot_test_attempts++;
    updatedMatric.tot_attempts = updatedMatric.tot_attempts + 1;
    updatedMatric.timeSpent = updatedMatric.timeSpent + newTime;
    if (isCorrect == 0) updatedMatric.tot_wa++;
    if (isCorrect == 1) updatedMatric.tot_ca++;
    //if (isCorrect == -1)
    //  updatedMatric.tot_wa++;

    ////////console.log(newTotalAttempts);
    updatedMatric.avg_acc = Math.round(
      100 * (updatedMatric.tot_ca / updatedMatric.tot_attempts),
      1
    );
    updatedMatric.avg_time = Math.round(
      updatedMatric.timeSpent / updatedMatric.tot_attempts,
      1
    );
    updatedMatric.avg_score =
      Math.round(
        (10 * (updatedMatric.tot_ca - 0.25 * updatedMatric.tot_wa)) /
          updatedMatric.tot_test_attempts
      ) / 10;
    updatedMatric.min_ptile =
      100 * (0.6 * updatedMatric.avg_acc + 0.4 * updatedMatric.avg_score);
    updatedMatric.min_ptile = Math.round(updatedMatric.min_ptile) / 100;

    setuserMatric(updatedMatric);

    if (status == 1) setIsSubmitted("1");
    else setIsSubmitted("-1");
    setShowAnswer(true);
  };

  const handleCloseLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  const handleSuccessfulLogin = () => {
    setIsLoginModalOpen(false);
    // Optionally, you can retry the next question logic here
    handleNextQuestion();
  };

  const handleNextQuestion = async () => {
    //////console.log("jsonObject", keys);
    setIsSubmitted("0");

    if (isLoading) return;
    if (!checkAuth()) {
      //setIsLoginModalOpen(true);

      const currentPath = location.pathname + location.search;
      const scrollPosition = window.scrollY || window.pageYOffset;

      history.push({
        pathname: "/login",
        state: { from: currentPath, scrollPosition },
      });
      return;
    }

    if (currentIndex >= nextqueArray.length) {
      // alert("All questions have been attempted.");
      setShowResult(true); // Show alert when all questions are sent
      return;
    }
    setIsLoading(true);
    const time = Math.floor(Date.now() / 1000);
    const timeExp = time + 15;
    const hash = generateHash(time, timeExp);

    try {
      const response1 = await fetch(
        `${BASE_URL}/getnxtq/${hash}/${time}/${timeExp}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            uid: userId,
            topic_id: allBlogData.topic_id,
            qid_req: nextqueArray[currentIndex],
            qid_curr: currentQuestion.questionId,
            user_metric: attempts[attempts.length - 1],
          }),
        }
      );
      //////console.log("resposense1", response1);

      const response = await response1.json();
      //////console.log("resposense", response);
      setquesMatric(response?.ques_metrics);
      const { currentQues } = response;
      setCurrentQuestion(currentQues);
      setSelectedOption(null);
      setShowAnswer(false);
      setTimer(0);
      setCurrentIndex(currentIndex + 1);
    } catch (error) {
      console.error("Error fetching next question:", error);
      alert("Failed to load next question. Please try again.");
    } finally {
      setcheckedans("0");
      setIsLoading(false); // Stop the loader whether the call succeeds or fails
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };
  const handleselected = (option, index) => {
    setSelectedOption(option);
    setcheckedans(index + 1);
  };
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  // const ResultScreen = () => {
  //   const totalQuestions = nextqueArray.length;
  //   const correctCount = attempts.filter((a) => a.status === 1).length;
  //   const wrongCount = attempts.filter((a) => a.status === -1).length;
  //   const accuracy = ((correctCount / totalQuestions) * 100).toFixed(2);
  //   const totalScore = userMatric1.avg_score;
  //   const percentile = userMatric1.min_ptile;
  //   const avgTime = userMatric1.avg_time;

  //   return (
  //     <div>
  //       <h2>Test Results</h2>
  //       <p>Total Questions: {totalQuestions}</p>
  //       <p>Correct Answers: {correctCount}</p>
  //       <p>Wrong Answers: {wrongCount}</p>
  //       <p>Accuracy: {accuracy}%</p>
  //       <p>Total Score: {totalScore}</p>
  //       <p>Percentile: {percentile}</p>
  //       <p>Average Time per Question: {avgTime} seconds</p>
  //       {/* <button onClick={() => window.location.reload()}>Restart Test</button> */}
  //     </div>
  //   );
  // };

  return (
    <Container>
      {isLoginModalOpen && (
        <LoginModalopen
          onClose={handleCloseLoginModal}
          onSuccessfulLogin={handleSuccessfulLogin}
        />
      )}
      <TwoColumn>
        <LeftColumn>
          <ParaBlock>
            {showResult ? (
              <ResultScreen
                nextqueArray={nextqueArray}
                attempts={attempts}
                userMatric={userMatric}
              />
            ) : (
              <QuizContainer>
                {testError}
                {!isStarted ? (
                  <ActionButton onClick={handleStartTest}>
                    Start Test
                  </ActionButton>
                ) : currentQuestion ? (
                  <>
                    <div tw="block">
                      <div tw="w-full bg-gray-100 p-4 mr-4 border border-gray-300 rounded">
                        <h3 tw="text-xl font-semibold mb-4">User Metrics</h3>
                        <div className="userMatric_Result">
                          <div className="userMatric_Result_Item">
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                padding: "5px",
                              }}
                            >
                              🎯 Accuracy %:
                              <strong>
                              {isNaN(userMatric?.avg_acc)
                                  ? "0.0"
                                  : userMatric?.avg_acc ?? "0.0"}/
                                {TopicMatric.avg_acc}
                              </strong>
                            </p>
                            <MetricResult
                              value={userMatric.avg_acc}
                              maxValue={TopicMatric.avg_acc}
                            />
                          </div>

                          <div className="userMatric_Result_Item">
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                padding: "5px",
                              }}
                            >
                              ✅ Avg Score:
                              <strong>
                              {isNaN(userMatric?.avg_score)
                                  ? "0.0"
                                  : userMatric?.avg_score ?? "0.0"}/
                                {TopicMatric.avg_score}
                              </strong>
                            </p>
                            <MetricResult
                              value={userMatric.avg_score}
                              maxValue={TopicMatric.avg_score}
                            />
                          </div>

                          <div className="userMatric_Result_Item">
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                padding: "5px",
                              }}
                            >
                              ⏳ Avg Time:
                              <strong>
                              {isNaN(userMatric?.avg_time)
                                  ? "0.0"
                                  : userMatric?.avg_time ?? "0.0"}/
                                {TopicMatric.avg_time}
                              </strong>
                            </p>
                            <MetricResult
                              value={userMatric.avg_time}
                              maxValue={TopicMatric.avg_time}
                            />
                          </div>

                          <div className="userMatric_Result_Item">
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                padding: "5px",
                              }}
                            >
                              🏆 YM Score:
                              <strong>
                                {isNaN(userMatric?.min_ptile)
                                  ? "0.0"
                                  : userMatric?.min_ptile ?? "0.0"}
                                /{TopicMatric.min_ptile}%
                              </strong>
                            </p>
                            <MetricResult
                              value={userMatric.min_ptile}
                              maxValue={TopicMatric.min_ptile}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <QuestionContainer>
                    <TimerContainer>
  {/* Left side: Timer and Time in a row */}
  <div>Question. {currentIndex}</div>
  <div className="flex flex-row items-center gap-2">
    <div>

    {/* <Timer /> */}
    </div>
    <div>

    <span className="whitespace-nowrap">🕒 Time: {timer} seconds</span>
    </div>
  </div>

  {/* Right side: Question Number */}
</TimerContainer>
                      <QuestionTitle>
                        {/* Q.{currentIndex } */}
                        {/* {currentQuestion.questionText} */}
                        {stripHtmlTags(currentQuestion.questionText)}
                      </QuestionTitle>

                      <OptionsContainer>
                        {currentQuestion.choices
                          .filter((option) => option.toUpperCase() !== "BLANK")
                          .map((option, index) => (
                            <OptionLabel
                              key={index}
                              style={{
                                backgroundColor:
                                  isSubmitted == "1" && checkedans == index + 1
                                    ? "#248747"
                                    : isSubmitted == "-1" &&
                                      checkedans == index + 1
                                    ? "#587cb6"
                                    : checkedans == index + 1
                                    ? "#587cb6"
                                    : "#f7fafd",

                                color:
                                  selectedOption === option
                                    ? "#fff"
                                    : "inherit",
                                padding: "15px 8px 15px 8px",
                                borderRadius: "4px",
                                cursor: "pointer",
                                transition: "background-color 0.3s ease",
                              }}
                            >
                              <input
                                type="radio"
                                name="option"
                                value={option}
                                checked={selectedOption === option}
                                onChange={() => handleselected(option, index)}
                                disabled={showAnswer}
                                tw="mr-3"
                              />

                              {option}
                            </OptionLabel>
                          ))}
                      </OptionsContainer>

                      {!showAnswer ? (
                        <ActionButton
                          onClick={handleViewAnswer}
                          disabled={!selectedOption}
                        >
                          View Answer
                        </ActionButton>
                      ) : (
                        <>
                          <ActionButton
                            onClick={handleNextQuestion}
                            bgColor="#da3e8c"
                          >
                            {isLoading ? (
                              // Loader can be text, a spinner component, or any indicator
                              <>
                                Loading ... <LLOADER />
                              </>
                            ) : (
                              "Next Question"
                            )}
                          </ActionButton>
                          {/* const textColor = status === 1 ? 'text-green-600' :
                      'text-red-600'; */}
                          {currentQuestion.correctAns == checkedans ? (
                            <div tw="mt-4 text-green-600">
                              Your Selected Option - {checkedans} -->{" "}
                              {currentQuestion.choices[checkedans]}{" "}
                              <strong>is Correct</strong>
                              <br></br>
                              <br></br>
                            </div>
                          ) : (
                            <>
                              <div tw="mt-4 text-red-600">
                                Your Selected Option - {checkedans} -->{" "}
                                {currentQuestion.choices[checkedans]}{" "}
                                <strong>is WRONG</strong>
                                <br></br>
                              </div>
                              <div tw="text-green-600">
                                Correct Answer is Option -{" "}
                                {currentQuestion.correctAns} -->{" "}
                                {
                                  currentQuestion.choices[
                                    currentQuestion.correctAns
                                  ]
                                }
                                <br></br>
                                <br></br>
                              </div>
                            </>
                          )}
                          <div tw="bg-[#f5f5f5] p-[5px]">
                            <b>Solution : </b>
                            {currentQuestion.solution}
                          </div>
                        </>
                      )}
                    </QuestionContainer>
                    <ThreeCharts
                      ques_metrics={quesMatric}
                      user_metrics={userMatric}
                    />
                  </>
                ) : null}
              </QuizContainer>
            )}
            {/* {attempts.length > 0 && <QuizProgressChart attempts={attempts} />} */}
            <Paragraph>
              {data && (
                <div
                  className="blogContainer"
                  dangerouslySetInnerHTML={{ __html: data }}
                ></div>
              )}
            </Paragraph>
          </ParaBlock>
        </LeftColumn>
        <RightColumn>
          <Sectionhead>Related Topics</Sectionhead>
          <LinkBlock>
            {relatedData && (
              <>
                {relatedData.map((val) => (
                  <Link
                    href={val.link.toLowerCase()}
                    key={val.id}
                    style={
                      val.id === blog_id
                        ? { color: "#597db6", fontWeight: "bold" }
                        : {}
                    }
                  >
                    {val.title}
                  </Link>
                ))}
              </>
            )}
          </LinkBlock>
        </RightColumn>
      </TwoColumn>
      {blogBanners != '0' && (
      <RelatedArticle blogBanners={blogBanners} blogProds={blogProds} />
      )}
      <DecoratorBlob />
    </Container>
  );
};
