import React, {useEffect,useRef} from "react";

import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/AurumHeroContent.js";
import Features from "components/features/ThreeColWithTopImage.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import WhyChoose from "components/features/TwoColWithThreeCardsLayout.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import OnlineBenefits from "components/features/ThreeColWithCenterImage.js";
import ToolsInfo from "components/tools/Tools.js";
import PracticeHearing from "components/tools/PracticeHearing.js";
import PracticeReading from "components/tools/PracticeReading.js";
import PracticeAccuracy from "components/tools/PracticeAccuracy.js";
import TranslateText from "components/tools/TranslateText.js";
// import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/DownloadApp.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import Footer from "components/footers/SimpleFiveColumn.js";
//import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import DesignIllustration from "images/banner.png";
import BroadCastImage from "images/aurum_Icons/broadcast.svg";
import ConferenceImage from "images/aurum_Icons/conference.svg";
import OnlineImage from "images/aurum_Icons/online.svg";
import EbooksImage from "images/aurum_Icons/ebook.svg";
import EventsImage from "images/aurum_Icons/event.svg";
import WebinarsImage from "images/aurum_Icons/web.svg";
import YoutubeImage from "images/aurum_Icons/yt.svg";
import APIsImage from "images/aurum_Icons/api.svg";
import DynamicContentImage from "images/aurum_Icons/dynamic.svg";
//import VideoContentImage from "images/aurum_Icons/aurum-video-library.svg";
import VideoContentImage from "images/youngminds_language.png";
import ContentSecurityImage from "images/aurum_Icons/aurum-contentSecurity.svg";
import DevicesImage from "images/aurum_Icons/aurum-devicesImage.svg";
import caseStudyLogoImage from "images/tsilogo.png";
import caseStudyProfileImage from "images/tsi_deepak_tek.png"
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
//import DesignIllustration from "images/aurum_Icons/heroImg.svg";
import StrongBrandImage from "images/learn_german.png";
import { components } from "ComponentRenderer.js";

// Live Chat plugin Tawkto

// seo plugin Helmet
import { Helmet } from 'react-helmet';
import Pronounciation from "images/icons/pronounciation.png";
import LiveClasses from "images/icons/live_classes.png";
import ExamPrepration from "images/icons/exam_prep.png";
import Hearing from "images/icons/hearing.png";
import Reading from "images/icons/reading.png";

import AcademicPartner from "images/icons/academic_partner.png";
import DynamicResources from "images/icons/dynamic_resources.png";
import UniquePedology from "images/icons/unique_pedagogy.png";
import FlexibleLearning from "images/icons/flexible_learning.png";
// const fs = require('fs');
//  <input type="file" id="filepicker" name="fileList" webkitdirectory='true' multiple  onChange={(e) => handleChange(e.target)} placeholder="Please choose directory having name" />
export default ({
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  }) => {
  const ref = useRef(null);
  const Subheading = tw.p`tracking-widest text-textBlack text-left md:text-center lg:text-center`;
  const HighlightedText = tw.span`text-primary-500`;
  const VerticalSeprator = tw.div`mt-20 xs:mt-0`;
  const WhyChooseWrap = tw.div`bg-baseGray overflow-hidden`;
  const Title = tw.h1`font-bold text-4xl md:text-4xl lg:text-5xl xl:text-5xl text-black leading-tight`;
  const Gtext=tw.span`text-brand-500 font-bold`;
  const Ltext=tw.span`text-brand-700 font-bold`;
  const Heading = tw.h4`text-black font-bold text-left xs:text-xl sm:text-2xl text-left md:text-left leading-tight`;
  
  const getSelectedId=(id)=>{
    ////////console.log(id);
    let secId= id.toString();
    ////////console.log(secId);
    const element = document.getElementById(secId);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }

  }
  
  // const tawkToPropertyId = '64538ccb31ebfa0fe7fbedf0';
  // const tawkToKey = '1gvj5rvt5';
  // useEffect(() => {
  //      tawkTo(tawkToPropertyId, tawkToKey)
  //  }, [])
  return (
    <>
      <Helmet
      
      title='Young Minds - Simplify Building Career in Finance'
      
      meta={[
        {
          name: 'description',
          content: 'Streamline and Simplify Building Career in Finance - CA, CS & Financial Markets',
        },
        {
          property: `og:title`,
          content: 'Young Minds - Simplify Building Career in Finance',
        },
        {
          property: `og:description`,
          content: 'Simplify Building Career in Finance and Global Career Goals',
        }]}
    />
   

    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} 
        mainTitle={<Title>Simplify Building<br /><Ltext>Career</Ltext><Gtext> in Finance</Gtext></Title>} 
        subTitle="Let your <b>Dreams to be a Finance Professional</b> turn into reality with Our Unmatched Experience, Team & Learning Flexibility."
        buttonText="Lets Talk"
        imageSrc={DesignIllustration}
        showClient= {false}
      />
      
        
        
        <OnlineBenefits />
        
        
        <MainFeature
          
          heading="AI Enabled, Advanced Platform for Learning"
          description="<p><b>Personalized Learning</b>: The platform uses machine learning algorithms to personalize the learning experience for each student, based on their strengths, weaknesses, and learning pace.</p><p><b>Interactive Content</b>: The platform offers content such as Hinglish Books for Self Learning, Videos, AI Enabled Self Assessment that engage students and simplify learning Finance.</p><p><b>Flexibility & Multi-device Support</b>: The platform is available 24/7, allowing students to learn at their own pace and platform is accessible on multiple devices such as smartphones, tablets, and laptops, making it easy to learn on-the-go.</p>"
          subheading=""
          textOnLeft={false}
          imageSrc={DevicesImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />

        <PreFooter fromPage = "Home Page" formheading = "Learning Finance in Early Life Amplies Your Financial and Career Prospects" formsubheading = "Get More Details About the Young Minds." heightlightText="Lets Start Learning"/>
      
      <Footer />
      </AnimationRevealPage>
   </>
  );
}
